var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container bg-white rounded-bottom"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col bg-blue rounded-top py-2 mb-4"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('span', {
    staticClass: "text-white h3"
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "list"
    }
  }), _vm._v(" Center Member Management")], 1), _c('router-link', {
    staticClass: "d-flex flex-column align-items-center justify-content-center rounded-circle bg-red text-white",
    staticStyle: {
      "height": "35px",
      "width": "35px"
    },
    attrs: {
      "to": {
        name: 'center-all-consultant-schedule'
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "calendar-alt"
    }
  })], 1)], 1)])]), _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.consultants, function (consultant, index) {
    return _c('div', {
      key: index,
      staticClass: "col-md-6 d-flex flex-column align-items-center"
    }, [_c('div', {
      staticClass: "w-75 rounded mb-4",
      staticStyle: {
        "box-shadow": "0 .5rem 1rem rgba(0,0,0,.15)"
      }
    }, [_c('router-link', {
      staticClass: "text-reset d-flex flex-column align-items-center",
      attrs: {
        "to": {
          name: 'center-consultant-schedule',
          params: {
            id: consultant.id
          }
        }
      }
    }, [_c('img', {
      staticClass: "mb-2",
      attrs: {
        "src": consultant.photo,
        "alt": "alt",
        "width": "250",
        "height": "250"
      }
    }), _c('p', {
      staticClass: "mb-1 font-weight-bold h4"
    }, [_vm._v(_vm._s(consultant.name))]), _c('div', {
      staticClass: "mb-2"
    }, [_c('span', {
      staticClass: "mr-2"
    }, [_c('font-awesome-icon', {
      attrs: {
        "icon": "thumbs-up"
      }
    }), _vm._v(" " + _vm._s(consultant.like))], 1), _c('span', {
      staticClass: "ml-2"
    }, [_c('font-awesome-icon', {
      attrs: {
        "icon": "thumbs-down"
      }
    }), _vm._v(" " + _vm._s(consultant.dislike))], 1)])])], 1)]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }