<template lang="pug">
.container.bg-white.rounded-bottom
  .row
    .col.bg-blue.rounded-top.py-2.mb-4
      .d-flex.justify-content-between
        span.text-white.h3
          font-awesome-icon(icon="list")
          |  Center Member Management
        router-link.d-flex.flex-column.align-items-center.justify-content-center.rounded-circle.bg-red.text-white(
          style="height: 35px; width: 35px"
          :to="{\
            name: 'center-all-consultant-schedule',\
          }"
        )
          font-awesome-icon(icon="calendar-alt")
  .row
    .col-md-6.d-flex.flex-column.align-items-center(v-for="(consultant, index) in consultants" :key="index")
      .w-75.rounded.mb-4(style="box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);")
        router-link.text-reset.d-flex.flex-column.align-items-center(
          :to="{\
            name: 'center-consultant-schedule',\
            params: {\
              id: consultant.id,\
            },\
          }"
        )
          img.mb-2(:src="consultant.photo" alt="alt" width="250" height="250")
          p.mb-1.font-weight-bold.h4 {{ consultant.name }}
          div.mb-2
            span.mr-2
              font-awesome-icon(icon="thumbs-up")
              |  {{ consultant.like }}
            span.ml-2
              font-awesome-icon(icon="thumbs-down")
              |  {{ consultant.dislike }}
</template>

<script>
import api from '@lioshutan/api-package';
import storage from '@/utils/localStorage';

export default {
  data: () => ({
    consultants: [],
  }),
  async created() {
    const response = await api
      .setDNS(process.env.VUE_APP_BASE_API)
      .consultant(storage.token)
      .getCenterConsultants();
    this.consultants = response.map(({
      hbConsultantId,
      englishName,
      picture,
      likeAmount,
      dislikeAmount,
    }) => ({
      id: hbConsultantId,
      name: englishName,
      photo: picture,
      like: likeAmount,
      dislike: dislikeAmount,
    }));
  },
  methods: {
    test() {
      this.$router.push({
        name: 'consultant-schedule',
        params: {
          id: 1,
        },
      });
    },
  },
};
</script>
